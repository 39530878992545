﻿/// <reference path="../../aboveFold.scss" />
/// DATEPICKER
.dp-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,.75)
}

.dp {
    position: relative;
    background: #fff;
    box-shadow: 2px 2px 16px rgba(0,0,0,.25);
    line-height: 1.4;
    border-radius: 4px;
    max-height: 400px;
    z-index: 1000;
    padding-top: 6px;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent
}

.dp:before {
    content: ' ';
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #3b99fc;
    background: linear-gradient(-90deg,#3b99fc 0,#8aefc8 100%)
}

.dp-permanent .dp {
    padding-top: 0;
    border: 1px solid #eee;
    box-shadow: none
}

.dp-permanent .dp:before {
    display: none
}

.dp-cal {
    min-height: 300px
}

.dp-below {
    position: absolute;
    font-size: .8em;
    width: 400px;
    max-width: 100vw
}

.dp-permanent {
    position: relative;
    font-size: .8em;
    width: 400px;
    max-width: 100vw
}

.dp-permanent .dp {
    z-index: 0
}

.dp-modal .dp {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 600px;
    width: calc(100% - 4em);
    transform: translate(-50%,-50%);
    animation: slide-up .3s forwards
}

.dp-months {
    padding: 24px
}

.dp-years {
    box-sizing: border-box;
    max-height: 400px;
    padding: 8px 0;
    overflow: auto !important
}

.dp-cal-month, .dp-cal-year, .dp-day, .dp-month, .dp-year {
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    position: relative;
    color: #3b404d;
    border-radius: 2px;
    border: 0;
    background: transparent
}

.dp-cal-header {
    position: relative;
    text-align: center;
    padding-bottom: 16px;
    background: #f5f5f5
}

.dp-next, .dp-prev {
    position: absolute;
    width: 30px;
    height: 30px;
    overflow: hidden;
    top: 14px;
    color: #777;
    border-radius: 2px;
    border: 0;
    background: transparent
}

.dp-next:focus, .dp-prev:focus, .dp-next:hover, .dp-prev:hover {
    outline: 0;
    color: inherit
}

.dp-prev {
    left: 24px
}

.dp-next {
    right: 24px
}

.dp-prev:before, .dp-next:before {
    content: '';
    border: 2px solid;
    width: 10px;
    height: 10px;
    display: inline-block;
    transform: rotate(-45deg);
    transition: border-color .2s;
    margin: 9px 0 40px 4px
}

.dp-prev:before {
    border-right: 0;
    border-bottom: 0
}

.dp-next:before {
    border-left: 0;
    border-top: 0;
    margin-left: 0;
    margin-right: 4px
}

.dp-cal-month, .dp-cal-year {
    display: inline-block;
    font-size: 1.4em;
    padding: 16px 8px 8px;
    outline: 0
}

.dp-cal-footer {
    text-align: center;
    background: #f5f5f5
}

.dp-day-today:after {
    content: '';
    height: 0;
    width: 0;
    border: 7px solid #227bd7;
    border-bottom-color: transparent;
    border-left-color: transparent;
    position: absolute;
    top: 0;
    right: 0
}

.dp-close, .dp-clear, .dp-today {
    box-sizing: border-box;
    display: inline-block;
    width: 33%;
    padding: 8px;
    text-decoration: none;
    color: inherit;
    border: 0;
    background: transparent
}

.dp-permanent .dp-close, .dp-permanent .dp-clear {
    display: none
}

.dp-close:active, .dp-clear:active, .dp-today:active, .dp-next:active, .dp-prev:active, .dp-cal-month:active, .dp-cal-year:active {
    background: #75bcfc;
    color: #fff
}

@media screen and (min-device-width:1200px) {
    .dp-close:hover, .dp-close:focus, .dp-clear:hover, .dp-clear:focus, .dp-today:hover, .dp-today:focus, .dp-next:hover, .dp-next:focus, .dp-prev:hover, .dp-prev:focus, .dp-cal-month:focus, .dp-cal-month:hover, .dp-cal-year:hover, .dp-cal-year:focus {
        background: #75bcfc;
        color: #fff
    }
}

.dp-col-header, .dp-day {
    width: 14.28571429%;
    display: inline-block;
    padding: 8px;
    text-align: center
}

.dp-col-header {
    color: #aaa;
    text-transform: uppercase;
    font-weight: 300;
    font-size: .8em;
    padding: 8px 0
}

.dp-month {
    width: 33%;
    display: inline-block;
    padding: 8px
}

.dp-year {
    display: block;
    padding: 8px 40px;
    width: 100%
}

.dp-edge-day {
    color: #aaa
}

.dp-day:hover, .dp-month:hover, .dp-year:hover, .dp-current:focus, .dp-current, .dp-day:focus, .dp-month:focus, .dp-year:focus {
    outline: 0;
    background: #75bcfc;
    color: #fff
}

.dp-selected:hover, .dp-selected:focus, .dp-selected {
    background: #3b99fc;
    color: #fff
}

.dp-day-disabled {
    background: transparent;
    color: #ddd
}

.dp-day-disabled:focus, .dp-day-disabled:hover {
    background: #ddd
}

.dp-focuser {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%
}

@media(max-width:480px),(max-height:480px) {
    .dp-modal .dp {
        font-size: .9em;
        width: auto;
        width: 100%
    }

    .dp-day-of-week, .dp-day {
        padding: 8px
    }
}

@keyframes slide-up {
    0% {
        transform: translate(-50%,100%)
    }

    100% {
        transform: translate(-50%,-50%)
    }
}

//// FOOTER

//[data-footer] {
//    background-color: #fff;
//    text-align: center;
//    padding: $padding-base * 2 0;
//    border-top: 8px solid $grey-warm;
//}
//
//.footer__nav {
//    padding-bottom: $padding-base * 3;
//}
//
//.footer--main__headline {
//    font-size: 1.6rem;
//    padding-bottom: $padding-base;
//    padding-top: 0;
//    white-space: nowrap;
//}
//
//.footer__label {
//    @media (max-width: $max-1199) {
//        &:hover {
//            cursor: pointer;
//
//            .footer--main__headline {
//                color: $cta-arrow-bg-hover;
//            }
//        }
//    }
//}
//
//.footer__input {
//    position: absolute;
//    opacity: 0;
//    top: 0;
//    left: 0;
//
//    @media (max-width: $max-1199) {
//        &:checked ~ .footer--main__list-wrapper {
//            max-height: 100vh;
//        }
//    }
//}
//
//.footer--main__list-wrapper {
//    @media (max-width: $max-1199) {
//        transition: max-height 1s ease;
//        overflow: hidden;
//        max-height: 0vh;
//        padding: 0;
//        max-height: 0;
//    }
//}
//
//.footer--main__list {
//    padding: $padding-base * 0.5 0 $padding-base;
//}
//
//.footer--main__listItem {
//    &:not(:last-child) {
//        padding-bottom: $padding-base * 0.5;
//    }
//}
//
//.footer--main__listItem__link {
//    color: $headline-color;
//    font-size: 1.4rem;
//    line-height: 1.5;
//
//    &:hover {
//        color: $primary-blue-1;
//    }
//
//    &:visited {
//        color: $headline-color;
//
//        &:hover {
//            color: $primary-blue-1;
//        }
//    }
//}
//
//.footer__social {
//    box-shadow: 0 -3px $grey-warm;
//    padding-top: $padding-base * 3;
//    padding-bottom: $padding-base * 2;
//}
//
//.footer__social__link {
//    font-family: $systemFontStackHeadlines;
//    font-weight: bold;
//    color: #000;
//    letter-spacing: -1px;
//    padding: 0 $padding-base;
//
//    span {
//        display: inline-block;
//        line-height: 1;
//        vertical-align: middle;
//
//        @media (max-width: $breakpoint-max-sm) {
//            display: none;
//        }
//    }
//
//    &:visited {
//        color: #000;
//    }
//}
//
//.footer__social__img {
//    display: inline-block;
//    line-height: 1;
//    vertical-align: middle;
//    margin-right: $padding-base * 0.5;
//}
//
//.footer__copyright {
//    color: $footer-link-grey;
//    font-size: 1.4rem;
//    padding-top: $padding-base * 2;
//}
//
//.footer__copyright__link {
//    color: $footer-link-grey;
//    padding: 0 $padding-base;
//
//    &:visited {
//        color: inherit;
//    }
//}
//
//.footer__certification__link {
//    padding: .5rem;
//    display: inline-block;
//    font-size:1.44rem;
//
//    img {
//        max-width: 2rem;
//        display: inline-block;
//        line-height: 1;
//        vertical-align: middle;
//    }
//}